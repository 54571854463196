import React, { useState } from 'react';
import './ProjectShowcase.css';

import bianalytiq from '../../assets/portfolio/bianalytiq.png'
import droplove from '../../assets/portfolio/droplove_export.png'
import xau from '../../assets/portfolio/xaufinance_2_export.png'
import geisha from '../../assets/portfolio/geisha_export.png'
import bthr from '../../assets/portfolio/bthr.png'

interface Project {
    title: string;
    description: string;
    image?: string;
    video?: string;
    technologies: string[];
    link: string;
    responsibilities: string[];
}

const projects: Project[] = [
    {
        title: "Bianalytiq - AI Data Intelligence Platform",
        description: "Product design and full-stack development. MVP stage and product validation in progress.",
        image: bianalytiq,
        technologies: ["LLama", "RAG", "Python", "Express", "React"],
        link: "https://bianalytiq.com/",
        responsibilities: ["Product design", "MVP development", "Market fit validation"]
    },
    {
        title: "Droplove.xyz - NFT Creator Platform",
        description: "Product design and full-stack development. Achieved the #1 Product of the day badge on Producthunt.com while onboarding 500+ user in one day.",
        image: droplove,
        technologies: ["Solidity", "Ethereum", "Node.js", "MongoDB", "React"],
        link: "https://droplove.xyz",
        responsibilities: ["Product design", "Full-stack development", "User onboarding"]
    },
    {
        title: "Agevolt EV Charging Tokenization",
        description: "Product design and full-stack development. Solution for creating a 'White Label Loyalty Tokens' for the owners of EV Charging Points (EVCP). Won first prize 30000€ in EU project competition.",
        video: "https://www.youtube.com/embed/gtGTXfg3n6c?si=fXiYqGWma7QzeGjt",
        technologies: ["Hyperledger Besu", "Solidity", "Nodejs", "AWS", "React"],
        link: "https://www.blockstart.eu/portfolio/agevolt/",
        responsibilities: ["Product design", "Full-stack development", "Investors presentation"]
    },
    {
        title: "Unimpressed Geisha - NFTs & Farming tool",
        description: "Full-stack development - platform + NFTs. Collection of 777 unique digital art collectables created by the female artist Zuzu Galova @inhideandseek. Trade volume in one day 14ETH.",
        image: geisha,
        technologies: ["Solidity", "Ethereum", "Node.js", "MongoDB", "React"],
        link: "https://www.unimpressedgeisha.com/",
        responsibilities: ["Business model innovation", "Full-stack development", "NFT implementation"]
    },
    {
        title: "XAU.finance - Tokenized Gold",
        description: "Smart contract design and development. The first DAG DeFi platform of its kind with market cap $10 000 000+",
        image: xau,
        technologies: ["Solidity", "Ethereum", "Truffle", "Hardhat"],
        link: "https://xau.finance/",
        responsibilities: ["Smart contract design", "Smart contract development", "DeFi platform implementation"]
    },
    {
        title: "Bethereum - p2p Sports Betting",
        description: "Orchestrated the development and execution of Bethereum ICO, successfully fundraising over $4 million",
        image: bthr,
        technologies: ["Solidity", "Ethereum", "Truffle"],
        link: "https://cryptototem.com/bethereum-bthr-ico/",
        responsibilities: ["Product design", "ICO development", "ICO execution"]
    }
];

const ProjectShowcase: React.FC = () => {
    const [activeProject, setActiveProject] = useState(0);

    return (
        <section id="work" className="standout-projects-section">
            <div className="max-w-7xl mx-auto">
                <h2 className="text-4xl md:text-5xl font-bold text-white text-center mb-2">Selected Works<span className="text-red-500">.</span></h2>
                <p className="text-xl text-gray-400 text-center mb-16">Projects that stand out with significance</p>
                <div className="project-showcase mt-24">
                    <div className="project-list">
                        {projects.map((project, index) => (
                            <div 
                                key={index} 
                                className={`project-item ${index === activeProject ? 'active' : ''}`}
                                onMouseEnter={() => setActiveProject(index)}
                            >
                                <h3 className="text-2xl font-semibold text-white mb-2">{project.title}</h3>
                                <p className="text-gray-300 mb-4">{project.description}</p>
                                <div className="technologies mb-4">
                                    {project.technologies.map((tech, i) => (
                                        <span key={i} className="technology-tag">{tech}</span>
                                    ))}
                                </div>
                                <div className="responsibilities mb-4">
                                    <h4 className="text-lg font-semibold text-white mb-2">My contribution:</h4>
                                    <ul className="list-disc list-inside text-gray-300">
                                        {project.responsibilities.map((responsibility, i) => (
                                            <li key={i}>{responsibility}</li>
                                        ))}
                                    </ul>
                                </div>
                                <a target="_blank" rel="noreferrer" href={project.link} className="project-link">View Project</a>
                            </div>
                        ))}
                    </div>
                    <div className="project-image mt-24">
                        {projects[activeProject].video ? 
                            <iframe width="100%" height="400" src={projects[activeProject].video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            :
                            <img src={projects[activeProject].image} alt={projects[activeProject].title} />
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectShowcase;