import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero/Hero';
import Bio from '../components/Bio/Bio';
import Experience from '../components/Experience/Experience';
import AboutMe from '../components/AboutMe/AboutMe';
import ProjectShowcase from '../components/ProjectShowcase/ProjectShowcase';
import PhotoGallery from '../components/PhotoGallery/PhotoGallery';
import WhyWorkWithMe from '../components/WhyWorkWithMe/WhyWorkWithMe';
import ContactSection from '../components/ContactSection/ContactSection';

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>Secret Sauce Digital | Ondrej Sarnecky - Your Tech Innovation Partner</title>
        <meta name="description" content="Discover the secret sauce for your business success - cutting-edge tech innovation to transform and elevate your company." />
        <meta name="keywords" content="tech innovation, full-stack development, AI, blockchain, business transformation, secret sauce, digital solutions" />
      </Helmet>
      <Hero />
      <Bio />
      <Experience />
      <AboutMe />
      <ProjectShowcase />
      <PhotoGallery />
      <WhyWorkWithMe />
      <ContactSection />
    </div>
  )
}