import React from 'react';
import './WhyWorkWithMe.css';
import OSCV from '../../assets/docs/Ondrej Sarnecky SSD CV.pdf'

const WhyWorkWithMe: React.FC = () => {
    const reasons = [
        {
            title: "Good understanding of Business & Tech",
            description: "With a background in both business and technology, I bridge the gap between these two worlds. I excel at translating business needs into technical solutions and vice versa, ensuring that projects not only function well but also align with strategic goals.",
            icon: "🧠" // You can replace this with an actual icon component or SVG
        },
        {
            title: "Agility & Experience",
            description: "Having worked independently for years, I'm agile and able to move quickly. I've launched large internationally publicised projects that had to work on day one so I understand the importance of reliability and performance.",
            icon: "🚀" // You can replace this with an actual icon component or SVG
        },
        {
            title: "Communication & Collaboration",
            description: "I'm a great communicator and collaborator and understand that you don't always care about *how* it gets done, just that it gets done well and on time.",
            icon: "🤝" // You can replace this with an actual icon component or SVG
        }
    ];

    return (
        <section className="why-work-with-me-section">
            <div className="container mx-auto px-6 py-32">
                <h2 className="text-4xl md:text-5xl font-bold text-white text-center mb-2">So what is the <span className="text-red-500">secret sauce</span>?</h2>
                <p className="text-xl text-gray-400 text-center mb-16">3 reasons why you should work with me</p>
                <div className="reasons-grid">
                    {reasons.map((reason, index) => (
                        <div key={index} className="reason-card">
                            <div className="reason-icon">{reason.icon}</div>
                            <h3 className="text-2xl font-semibold text-white mb-4">{reason.title}</h3>
                            <p className="text-gray-300">{reason.description}</p>
                        </div>
                    ))}
                </div>
                <div className="cta-container">
                    <a href={OSCV} target="_blank" rel="noreferrer">
                        <button className='px-8 py-3 border-2 hover:bg-white text-white-500 hover:text-black font-semibold full hover:bg-opacity-90 transition duration-300'>Download CV</button>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default WhyWorkWithMe;