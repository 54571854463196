import React, { useEffect, useState } from 'react';

const GradientOverlay: React.FC = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            console.log(position);
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        <div className="fixed inset-0 pointer-events-none z-0">
            <div 
            style={{ 
                opacity: Math.max(0, 1 - scrollPosition / (window.innerHeight * 2.25))
            }}
            className="absolute inset-0 bg-gradient-to-b from-transparent via-purple-900/10 to-purple-900"></div>
        </div>
    );
};

export default GradientOverlay;