import React from 'react';
import Header from '../components/Header/Header';
import { Link } from 'react-router-dom';
import './Blog.css';

interface BlogPost {
  id: number;
  title: string;
  excerpt: string;
  date: string;
}

const blogPosts: BlogPost[] = [
  {
    id: 1,
    title: "The Future of Web Development",
    excerpt: "Exploring upcoming trends in web development and how they will shape the industry.",
    date: "2023-08-01"
  },
  {
    id: 2,
    title: "Mastering React Hooks",
    excerpt: "A deep dive into React Hooks and how they can improve your code quality and efficiency.",
    date: "2023-07-15"
  },
  {
    id: 3,
    title: "The Impact of AI on Software Development",
    excerpt: "Examining how artificial intelligence is changing the landscape of software development.",
    date: "2023-07-01"
  }
];

const Blog: React.FC = () => {
    return (
      <div className="blog-container min-h-screen">
        <Header />
        <div className="container mx-auto px-4 pt-24 pb-16">
          <h1 className="text-4xl font-bold text-white mb-8 pt-32">Blog</h1>
          <div className="space-y-6">
            {blogPosts.map((post) => (
              <div key={post.id} className="blog-card p-6 rounded-lg">
                <h2 className="text-2xl font-semibold text-white mb-2">{post.title}</h2>
                <p className="text-gray-400 mb-4">{post.excerpt}</p>
                <div className="flex justify-between items-center">
                  <p className="text-sm text-gray-500">{post.date}</p>
                  <Link 
                    to={`/blog/${post.id}`} 
                    className="read-button px-4 py-2 text-white text-sm rounded"
                  >
                    Read
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default Blog;