import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Home from './pages/Home';
import Blog from './pages/Blog';
import ComingSoon from './pages/ComingSoon';
import CustomCursor from './components/CustomCursor/CustomCursor';
import Background from './components/Background/Background';
import GradientOverlay from './components/GradientOverlay/GradientOverlay';

const App: React.FC = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ConsultationService",
    "name": "Secret Sauce Digital by Ondrej Sarnecky",
    "description": "Ondrej Sarnecky - Your secret sauce for tech innovation. I'm helping companies grow in their revenue by making them cool.",
    "url": "https://secretsauce.digital",
    "founder": {
      "@type": "Person",
      "name": "Ondrej Sarnecky"
    },
    "areaServed": "Worldwide",
    "serviceType": ["Tech Innovation", "Full-stack Development", "AI Development", "Blockchain Solutions"]
  };

  return (
    <Router>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Background />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
      </Routes>
      <CustomCursor />
      <GradientOverlay />
    </Router>
  );
}

export default App;