import React, { useRef, useState, useEffect } from 'react';
import './Experience.css';
import { FaChevronDown } from 'react-icons/fa';

import zse from '../../assets/logos/zse.png'
import nehnutelnosti from '../../assets/logos/nehnutelnostisk-logo-light.png'
import ey from '../../assets/logos/ey.png'
import dxb from '../../assets/logos/dxb.png'
import c13 from '../../assets/logos/c13.webp'
import cam from '../../assets/logos/cambridgeuni.png'
import agevolt from '../../assets/logos/agevolt.png'
import za from '../../assets/logos/zilina.jpg'
import bsk from '../../assets/logos/bsk.webp'
import accenture from '../../assets/logos/accenture.png'
import beth from '../../assets/logos/beth.png'
import mobilityxlab from '../../assets/portfolio/mobilityxlab.png'
import neu from '../../assets/logos/neu.svg'

interface ExperienceItem {
    years: string;
    title: string;
    description: string;
    images: string[];
    link?: string;
}

const experienceData: ExperienceItem[] = [
    // {
    //     "years": "2023 - Present",
    //     "title": "Senior Backend Dev / Project Tech Lead - GoodRequest",
    //     "description": "Leading the development team for largest EV charging solution provider in Slovakia - ZSE Drive. Providing backend development service for largest real estate platform in central europe - Nehnutelnosti.sk.",
    //     "images": [zse, nehnutelnosti],
    //     "link": "https://goodrequest.com/"
    // },
    {
        "years": "2022 - 2023",
        "title": "Cohort 5 - Carbon13 - Cambridge University",
        "description": "Hand-picked for Cohort 5 by Carbon 13, a venture builder dedicated to fostering start-ups that aim to reduce carbon emissions. I was among the top 10% selected from over 1000+ applicants, a testament to my technological innovation capabilities and environmental commitment.",
        "images": [c13, cam],
        "link": "https://carbonthirteen.com/"
    },
    {
        "years": "2022 - 2024",
        "title": "Blockchain Expert / Consultant - Ernst & Young",
        "description": "Responsible for a blockchain use-case research and assessment workshop for Dubai Airport, and in-person presentation of selected use-cases to Dubai Airport's VP's. This facilitated the closure of a lucrative deal between Ernst & Young and Dubai Airport.",
        "images": [ey, dxb],
        "link": "https://www.ey.com/cs_cz"
    },
    {
        "years": "2021 - 2022",
        "title": "Head of Digital Platform - AgeVolt",
        "description": "Ensuring research and development activities will maintain an organization's competitive position and profitability. R&D team management (building the team from 0 to 12 team members) Presentations for partners, clients, suppliers and investors. Adjusting and responsibility for the budget. Daily communication with team colleagues and solving their blockers. Focusing on implementation of blockchain technology into infrastructure and products of AgeVolt Digital Platform.",
        "images": [agevolt],
        "link": "https://www.agevolt.com/"
    },
    {
        "years": "2021 - 2021",
        "title": "mobilityXlab Finalist - AgeVolt representative",
        "description": "MobilityXlab is a collaboration hub within mobility offering startups a fast-track into a potential strategic partnership with industry leaders. I was representing the AgeVolt in front of strategic partners like Ericsson, Magna, Polestar, Volvo Group, Zeekr Technology Europe, and Zenseact. Researching and presenting the collaboration opportunities and join-ventures.",
        "images": [mobilityxlab],
        "link": "https://www.mobilityxlab.com/"
    },
    {
        "years": "2018 - 2022",
        "title": "Borad Member - Neuromorphics Europe",
        "description": "Neuromorphics Europe is focusing on the implementation of the latest advances in the field of Neuromorphic in real life. Periodic Evangelization Meet-ups, Review of Knowledge Base by Academic community, PoC Lab. Humanitarian a Cognitive Sciences design pattern synthesis – New Way of working development.",
        "images": [neu],
        "link": "https://neuromorphics.eu/"
    },
    {
        "years": "2020 - 2020",
        "title": "Adjunct Lecturer in Cryptocurrencies - University of Zilina",
        "description": "Teaching Cryptocurrency and Blockchain course for students of Informatics.",
        "images": [za],
        "link": "https://www.uniza.sk/index.php/en/"
    },
    {
        "years": "2017 - 2022",
        "title": "Head of Technology & Tech Consultant - Blockchain Slovakia NGO",
        "description": "Providing consultation and direct participation in the creation of cryptocurrency regulation legislation with Slovak government representatives. Speaker at various blockchain/crypto conferences.",
        "images": [bsk],
        "link": "https://blockchainslovakia.sk/"
    },
    {
        "years": "2019 - 2021",
        "title": "Application Development Senior Analyst - Accenture",
        "description": "Partially focusing on experimental blockchain projects supporting the Accenture innovation position on the market and offering the existing clients new innovative approaches and solutions and partially part of a NATO security project in secure information-restricted mode.",
        "images": [accenture],
        "link": "https://www.accenture.com/sk-en"
    },
    {
        "years": "2017 - 2019",
        "title": "Lead Blockchain & Smart Contracts Dev - Bethereum",
        "description": "Responsible for the organization and execution of token sale fundraising and the development of Ethereum smart contracts used as a core backend of the Bethereum. Smart contracts represented the betting logic in decentralized peer-to-peer betting solution.",
        "images": [beth],
        "link": "https://coinmarketcap.com/currencies/bethereum/"
    },
    {
        "years": "2014 - 2017",
        "title": "Freelance Web Dev",
        "description": "Working as a freelance developer for various clients. Most of the projects been short-term gigs like creating web pages, ecommerce solutions etc.",
        "images": [],
    }
];

const Experience: React.FC = () => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const [scrollProgress, setScrollProgress] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const [isGlowing, setIsGlowing] = useState(false);

    useEffect(() => {
        let ww: any = window
        const handleScroll = () => {
            if (carouselRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = carouselRef.current;
                const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
                setScrollProgress(scrollPercentage);

                // Calculate active index based on scroll position
                const itemHeight = clientHeight; // Assuming each item takes full height
                const newIndex = Math.round(scrollTop / itemHeight);
                if (newIndex !== activeIndex) {
                    setActiveIndex(newIndex);
                }

                // Set isScrolling to true and clear any existing timeout
                setIsScrolling(true);
                if (ww.scrollTimeout) {
                    clearTimeout(ww.scrollTimeout);
                }

                // Set a timeout to hide the progress bar after scrolling stops
                ww.scrollTimeout = setTimeout(() => {
                    setIsScrolling(false);
                }, 1000); // Adjust this value to change how long the bar remains visible after scrolling
            }
        };

        const carouselElement = carouselRef.current;
        if (carouselElement) {
            carouselElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (carouselElement) {
                carouselElement.removeEventListener('scroll', handleScroll);
            }
            if (ww.scrollTimeout) {
                clearTimeout(ww.scrollTimeout);
            }
        };
    }, [activeIndex]);

    const renderImages = (images: string[], index: number) => {
        const imageCount = images.length;
        return (
            <div className={`image-container ${index === activeIndex ? 'active' : ''} ${imageCount === 1 ? 'single-image' : ''}`}>
                {images.map((img, imgIndex) => (
                    <div key={imgIndex} className="image-wrapper">
                        <img
                            key={imgIndex}
                            src={img}
                            alt={`Experience ${index + 1} - Image ${imgIndex + 1}`}
                            className="experience-image"
                        />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <section id="experience" className="experience-section min-h-screen flex items-center bg-gradient-to-b from-black to-black">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col lg:flex-row items-center">
                    <div className="w-full lg:w-1/2 mb-8 lg:mb-0 relative">
                        <h2 className="text-3xl sm:text-5xl font-bold mb-4 text-white">My Experience<span className="text-red-500">.</span></h2>
                        {/* <p className="text-lg sm:text-xl text-white mb-8">Scroll to explore my professional journey</p> */}
                        <p className="text-lg sm:text-xl text-white mb-8">Not just clients, but partners for life</p>
                        <div className="image-grid mt-8 lg:mt-24">
                            {experienceData.map((item, index) => renderImages(item.images, index))}
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 relative">
                        <div ref={carouselRef} className="experience-carousel pt-8 lg:pt-24">
                            {experienceData.map((item, index) => (
                                <div key={index} className="experience-item">
                                    <h3 className="text-xl sm:text-2xl font-bold mb-2 text-white">{item.title}</h3>
                                    <h4 className="text-sm sm:text-base mb-4 text-white">{item.years}</h4>
                                    <p className="text-sm sm:text-base text-white">{item.description}</p>
                                    {item.link && (
                                        <a target='_blank' rel='noreferrer' href={item.link}>
                                            <button className='experience-link text-white mt-6 sm:mt-12'>View company</button>
                                        </a>
                                    )}
                                    <div 
                                        className={`chevron absolute bottom-6 left-1/2 transform -translate-x-1/2 z-20 cursor-pointer ${isGlowing ? 'glow' : ''}`}
                                        onClick={() => {
                                            const nextIndex = (activeIndex + 1) % experienceData.length;
                                            setActiveIndex(nextIndex);
                                            const carouselElement = carouselRef.current;
                                            if (carouselElement) {
                                                carouselElement.scrollTo({
                                                    top: nextIndex * carouselElement.clientHeight,
                                                    behavior: 'smooth'
                                                });
                                            }
                                            setIsGlowing(true);
                                            setTimeout(() => setIsGlowing(false), 2000);
                                        }}
                                    >
                                        <FaChevronDown className="text-white text-4xl animate-bounce opacity-10" />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="gradient-overlay"></div>
                    </div>
                    {/* <div className="chevron absolute bottom-6 left-1/2 transform -translate-x-1/2 z-20">
                        <FaChevronDown className="text-white text-4xl animate-bounce" />
                    </div> */}
                </div>
            </div>
            <div className={`progress-bar-container ${isScrolling ? 'visible' : ''}`}>
                <div
                    className="progress-bar"
                    style={{ width: `${scrollProgress}%` }}
                ></div>
            </div>
        </section>
    );
};

export default Experience;